import React, { ReactNode, ReactElement } from "react"

interface MDXWrapperProps {
  children?: ReactNode | ReactNode[]
}

const MDXWrapper: React.FC = ({ children }: MDXWrapperProps): ReactElement => (
  <div className="mdx-wrapper bg-white rounded mb-8">{children}</div>
)

export default MDXWrapper
