import React, { ReactElement } from "react"
import { graphql, Link } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"

import MDXWrapper from "../components/mdx-wrapper"
import SEO from "../components/SEO"

export const query = graphql`
  query Post($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        title
        date(formatString: "MMMM D, YYYY")
        tags
      }
      body
    }
  }
`

const PostTemplate: React.FC = ({ data: { mdx: post } }: any): ReactElement => {
  return (
    <section className="flex flex-col justify-between min-h-screen bg-gray-200">
      <SEO title={post.frontmatter.title}></SEO>
      <article className="flex-grow markdown">
        <section className="px-8 py-16 text-center">
          <h2 className="uppercase text-xl tracking-wide font-semibold hover:underline text-gray-700">
            <Link to="/">Michael Anhari</Link>
          </h2>
          <h1 className="text-4xl md:text-5xl mt-8 font-semibold max-w-4xl mx-auto">
            {post.frontmatter.title}
          </h1>
          <div className="p-0 mt-4 mb-0 text-center text-gray-700">
            <span>{post.frontmatter.date}</span>
            <ul className="mt-2 font-sans text-center space-x-2">
              {post.frontmatter.tags &&
                post.frontmatter.tags.map(
                  (tag: any, index: any): ReactElement => (
                    <li
                      key={index}
                      className="inline-block text-sm leading-none text-gray-600 bg-gray-300 p-1"
                    >
                      {tag}
                    </li>
                  )
                )}
            </ul>
          </div>
        </section>

        <MDXWrapper>
          <MDXRenderer>{post.body}</MDXRenderer>
        </MDXWrapper>
      </article>
    </section>
  )
}

export default PostTemplate
